<template>
    <div>
        <v-flex class="mr-2 mb-3">
            
            <div>
                Załączniki kursanta
                <div
                    text
                    v-for="(attachment, index) in filesCourse"
                    :key="index"
                    class="wordbreak mb-3 mt-4 d-flex justify-center align-center" 
                >
                    <a 
                        v-if="attachment.path" 
                        :href="attachment.path"
                    >
                        {{attachment.originalName}}
                    </a>
                    <v-btn 
                        small 
                        class="ml-5"
                        @click="deleteAttachmentCourse(attachment._id)"
                    >
                        X
                    </v-btn>
                </div>
            </div>
            <div>
                <v-form
                    ref="formCopy"
                    class="d-flex align-baseline"
                    v-if="(role === 'praktykant' || role === 'administrator' || role === 'moderator' || role === 'kursant') && status != 'Zakończone'"
                >
                <v-file-input
                    id="fileCourse"
                    type="file"
                    v-on:change="onAddNewFileCourse"
                    label="Dodaj załącznik"
                ></v-file-input>
                    <v-btn 
                        :disabled="!this.fileCourse"
                        small 
                        class="ml-4"
                        :loading="isLoadedCopy"
                        @click="sendFileCourse">
                        Dodaj
                    </v-btn>
                </v-form>
            </div>
        </v-flex>
        <v-divider></v-divider>
        <v-flex class="mt-3 ml-2">
            <div>
                Załączniki Korektora
                <div
                    text
                    v-for="(attachment, index) in filesKorektor"
                    :key="index"
                    class="mb-3 mt-4 wordbreak" row>
                    <a 
                        v-if="attachment.path" 
                        :href="attachment.path"> 
                            {{attachment.originalName}}
                    </a>
                    <v-btn
                        v-if="(role === 'korektor' || role === 'administrator' || role === 'moderator') && status != 'Zakończone'"
                        small 
                        class="ml-5" 
                        @click="deleteAttachmentKorektor(attachment._id)"
                    >
                        X
                    </v-btn>
                </div>
            </div>
            <div v-if="status == 'Do sprawdzenia'">
                <v-form 
					ref="formKorektor"
					class="d-flex align-baseline"
					v-if="(role === 'korektor' || role === 'administrator' || role === 'moderator') && status != 'Zakończone'"
					row
				>
					<v-file-input
						id="fileKorektor"
						type="file"
						v-on:change="onAddNewFileKorektor"
						label="Dodaj załącznik"
					></v-file-input>
					<v-btn 
						small 
						class="ml-4"  
						:disabled="!this.fileKorektor"
						@click="sendFileKorektor"
						:loading="this.isLoadedKorektor"
						>
							Dodaj
					</v-btn>
                </v-form>
            </div>
        </v-flex>
    </div>
</template>

<script>
import io from 'socket.io-client';

const token = localStorage.getItem('user');

export default {
	name: 'AttachmentsCourse',
    computed: {
        role(){
            return this.$store.getters.getUserRole;
		},
    }, 
    props:{
        status: {
            type: String,
        },
        oldStatus: {
            type: Object,
        }
    },

    data(){
        return{
			isLoadedCopy: false,
			isLoadedKorektor: false,

            socket: io(this.url),

            filesCourse: [],
            filesKorektor: null,
            filesAttached: null,
            fileCourse: null,
			fileKorektor: null,
        }
    },
    methods: {
        onAddNewFileCourse(){
			const fileToUpload = document.querySelector('#fileCourse').files[0]
            this.fileCourse = fileToUpload
        },
        onAddNewFileKorektor(){
            const fileToUpload = document.querySelector('#fileKorektor').files[0]
            this.fileKorektor = fileToUpload
        },
        sendFileCourse(){
            if (this.fileCourse != null){
                let formData = new FormData();
				this.isLoadedCopy = true;
                formData.append('file', this.fileCourse);
                formData.append('id', this.$route.params.idtask);
                formData.append('courseId', this.$route.params.id);
                
                this.$axios.post(`${this.url}api/course/course-file`, formData, { headers: { 'x-access-token': token } })
                .then(() => {
					this.$refs.formCopy.reset()
					this.isLoadedCopy = false
					this.fileCourse = null
                })
            }
        },
        sendFileKorektor(){
            if (this.fileKorektor != null){
                let formData = new FormData();
				this.isLoadedCopy = true;
                formData.append('file', this.fileKorektor);
                formData.append('id', this.$route.params.idtask);
                formData.append('courseId', this.$route.params.id);

                this.$axios.post(`${this.url}api/course/course-file-korektor`, formData, { headers: { 'x-access-token': token } })
                .then(() => {
                    // this.$router.go()
					this.$refs.formKorektor.reset()
					this.isLoadedKorektor = false
					this.fileKorektor = null
                })
            }
            
        },
        // getAttachedFiles() {
        //     this.$axios
        //         .get(`${this.url}api/course/course-file/${this.$route.params.idtask}`)
        //         .then((resp) => {
        //             this.filesAttached = resp.data;
        //         });
        // },
        
        getAttachKorektor(){
            this.$axios
            .get(`${this.url}api/course/course-file-korektor/${this.$route.params.idtask}/${this.$route.params.id}`, { headers: { 'x-access-token': token } })
            .then(resp =>{ 
				this.filesKorektor = resp.data });
        },
        getAttachCourse(){
            this.$axios
            .get(`${this.url}api/course/course-file/${this.$route.params.idtask}/${this.$route.params.id}`, { headers: { 'x-access-token': token } })
            .then(resp =>{
                this.filesCourse = resp.data
            });
        },
        deleteAttachmentCourse(id){
            this.$axios.delete(`${this.url}api/course/course-file/${id}`, { headers: { 'x-access-token': token } })
            .catch((error) => {
                throw error.response.data
            })
        },
        deleteAttachmentKorektor(id){
            this.$axios.delete(`${this.url}api/course/course-file-korektor/${id}`, { headers: { 'x-access-token': token } })
            .catch((error) => {
                throw error.response.data
            })
        },
    },
    created() {
        this.getAttachKorektor()
        this.getAttachCourse()

        this.socket.on('newAttachAddedCourse', () => {
			this.getAttachCourse()
        });
        this.socket.on('newAttachAddedKorektor', () => {
            this.getAttachKorektor()
        });
        this.socket.on('attachmentCourseRemoved', () => {
            this.getAttachCourse()
            this.getAttachKorektor()

        });
    },
}
</script>

<style lang="scss" scoped>
	.wordbreak { 
		word-break: break-all;
		a{
			word-break: break-all;
		}
	}
</style>