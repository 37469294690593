<template>
    <div class="task">
        <v-container class="my-5 px-10">
          <h1 v-if="role === 'administrator' || role === 'moderator' || role === 'korektor'">{{name}}</h1>
            <v-layout row class="my-4">
            <v-flex md2 class="mx-2" >
                <div class="d-flex align-center mb-4"  v-if="role === 'kursant' && status === 'Przyjęte'">
                  <v-btn
                    color="green"
                    class="mr-2 white--text"
                    @click.stop="done()"
                  >
                    Tekst gotowy
                  </v-btn>
                </div>
                <div class="d-flex align-center"  v-if="(role === 'korektor') && status === 'Do sprawdzenia'">
                  <v-btn
                    color="green"
                    class="mr-2 mb-4 white--text"
                    @click.stop="end()"
                  >
                    Zadanie zakończone
                  </v-btn>
                </div>
                <v-layout>
                <v-card min-height="50" class="mr-5 px-5">
                  <v-list-item two-line >
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1">{{signs}}</v-list-item-title>
                        <v-list-item-subtitle>Znaki</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card min-height="50" v-if="role === 'administrator' || role === 'moderator'">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-select
                        :items="allStatus"
                        v-model="status"
                        @change="changedStatus"
                      ></v-select>
                      <v-list-item-subtitle>Status</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                </v-layout>
            </v-flex>
            </v-layout>
            <v-layout row class="my-4" >
                <v-flex md8 >
                  <v-card height="600" class="scroll mr-10 py-6 px-6 text-left">
                      <h2 class="text-left mb-4">{{title}}</h2>
                      <div v-html="description"></div>
                        <div
                            text
                            v-for="(attachment, index) in filesAttachedOriginal"
                            :key="index+999"
                        >
                            <template v-if="checkFilesOriginal(attachment)">
                                <div class="mb-3 d-flex mt-4 mx-8 align-center"> 
                                    <a :href="attachment.path">
                                        {{ attachment.originalName }}
                                    </a>
                                    <v-btn
                                        v-if="
                                            role === 'administrator' ||
                                            role === 'moderator' ||
                                            role === 'korektor'
                                        "
                                        small
                                        class="ml-5"
                                        @click="rmAttachment(attachment)"   
                                    >
                                        X
                                    </v-btn>
                                </div>
                            </template>
                        </div>
                        <div
                            text
                            v-for="(attachment, index) in filesAttached"
                            :key="index"
                        >
                            
                            <template v-if="checkFiles(attachment)">
                                <div class="mb-3 d-flex mt-4 mx-8 align-center">
                                    <a :href="attachment.path">
                                        {{ attachment.originalName }}
                                    </a>
                                    <v-btn
                                        v-if="
                                            role === 'administrator' ||
                                            role === 'moderator' ||
                                            role === 'korektor'
                                        "
                                        small
                                        class="ml-5"
                                        @click="rmAttachment(attachment)"   
                                    >
                                        X
                                    </v-btn>
                                </div>
                            </template>
                        </div>
                        <v-form
                            ref="form"
                            class="d-flex align-baseline"
                            v-if="(role === 'administrator' || role === 'moderator' || role === 'korektor') && status != 'Zakończone'"
                        >
                            <v-file-input
                                id="file"
                                type="file"
                                v-on:change="onAddNewFile"
                                label="Dodaj załącznik"
                            ></v-file-input>
                            <v-btn
                                small
                                class="ml-4"
                                @click="sendFile"
                                :loading="isLoaded"
                                :disabled="!file"
                            >
                                Dodaj
                            </v-btn>
                        </v-form>
                  </v-card>

                </v-flex>

              <v-flex md4>
                <v-card  height="600" class="scroll pa-3">
                  <CourseAttachmets :taksId="this.id" :status="this.status" />
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex >
                  <v-card class="pa-4">
                    <CourseComments />
                  </v-card>
              </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import io from 'socket.io-client';
import CourseAttachmets from '@/components/organisms/CourseAttachmets.vue';
import CourseComments from '@/components/organisms/CourseComments.vue';

const token = localStorage.getItem('user');

export default{
    name: "TaskCourse",
    components: {
        CourseAttachmets,
        CourseComments
    },
    computed: {
        role(){
          return this.$store.getters.getUserRole;
        },
        userID(){
          return this.$store.getters.getUserID;
      },
    },
    data(){
      return{
			isLoaded: false,
			id: this.$route.params.id,
			taskId: this.$route.params.idtask,
            description: null,
            title: null,
            socket: io(this.url),
            text: null,
            modalToCheck: false,
            signs: null,
            name: null,
            status: 'Przyjęte',
            feedback: null,
            correctorId: null,
            tasks: [],
            menu: false,
            secondTask: null,
            filesAttached: null,
            filesAttachedOriginal: null,
            file: null,
            allStatus: [
                { text: 'Przyjęte' },
                { text: 'Do sprawdzenia' },
                { text: 'Zakończone' },
			],
        }
    },
    methods: {
        deleteAttachment(id) {
            this.$axios
                .delete(`${this.url}api/uploads/delete-attach/${id}`, { headers: { 'x-access-token': token } })
                .catch((error) => {
                    throw error.response.data;
                });
        },
        rmAttachment(toRm) {
            let removeThese;

            if(toRm.removeIn){
                removeThese = toRm.removeIn;
                removeThese = JSON.parse(removeThese);
            }else{
                removeThese = [];
            }

            removeThese.push(this.$route.params.id);
            removeThese = JSON.stringify(removeThese);

            this.$axios
                .patch(`${this.url}api/uploads/update-attach`,  {
                    courseId: toRm._id,
                    toRemove: removeThese
                }, { headers: { 'x-access-token': token } })
                .catch((error) => {
                    throw error.response.data;
                });
        },
        done(){
            this.$axios.put(`${this.url}api/course/task-status`, {
            status: 'Do sprawdzenia',
            courseId: this.$route.params.id,
            taskid: this.$route.params.idtask,
            date: new Date().toISOString(),
            correctorMail: this.correctorId
          }, { headers: { 'x-access-token': token } })
        },
        end(){
          this.$axios.put(`${this.url}api/course/task-status`, {
            status: 'Zakończone',
            courseId: this.$route.params.id,
            taskid: this.$route.params.idtask,
            date: new Date().toISOString(),
            correctorMail: this.correctorId
          }, { headers: { 'x-access-token': token } })
        },
        changedStatus(item){
          this.$axios.put(`${this.url}api/course/task-status`, {
            status: item,
            courseId: this.$route.params.id,
            taskid: this.$route.params.idtask,
            date: new Date().toISOString(),
            correctorMail: this.correctorId
          }, { headers: { 'x-access-token': token } })
        },
        getCourse(){
          this.$axios.get(`${this.url}api/course/${this.id}`, { headers: { 'x-access-token': token } })
          .then(el => {
            this.correctorId = el.data.correctorId;
            this.tasks = el.data.tasks;
            this.name =  el.data.name;
            this.tasks.forEach(element => {
              if( element._id == this.taskId){
                this.title = element.title;
                this.secondTask = element._id;
                this.description = element.desciption;
                this.status = element.status;
                this.signs = element.characters ? element.characters : 3000;
              }
            });
          })
        },
        getAttachedFiles() {
            this.$axios
                .get(`${this.url}api/uploads/course-attachment/${this.$route.params.id}`, { headers: { 'x-access-token': token } })
                .then((resp) => {
                    this.filesAttached = resp.data;
                });
        },
        getAttachedFilesOriginal() {
            this.$axios
                .get(`${this.url}api/uploads/course-attachment/${this.$route.params.idtask}`, { headers: { 'x-access-token': token } })
                .then((resp) => {
                    this.filesAttachedOriginal = resp.data;
                });
        },
        sendFile() {
            if (this.file != null) {
                let formData = new FormData();
                this.isLoaded = true;

                formData.append("file", this.file);
                formData.append("id", this.id);
                formData.append("emmit", false);
                formData.append("task", this.$route.params.idtask)
                this.$axios
                    .post(this.url + "api/uploads/course-attach", formData, { headers: { 'x-access-token': token } })
                    .then(() => {
                        this.$refs.form.reset();
                        this.isLoaded = false;
                        this.file = null;
                    });
            }
            // if (this.file != null) {
            //     let formData = new FormData();

            //     formData.append("file", this.file);
            //     formData.append("id", taskId);
            //     formData.append("emmit", false);
            //     this.$axios
            //         .post(this.url + "api/course/course-file", formData)
            //         .then(() => {
            //             this.file = null;
            //         });
            // }
        },
        onAddNewFile() {
            const fileToUpload = document.querySelector("#file").files[0];
            this.file = fileToUpload;
        },
        checkFiles(arg){
            let arr = arg.removeIn;
            let idSecond = arg.taskIdTwo;

            if(arr.includes(this.$route.params.id)){

                return false;
                
            }else{

                if(this.secondTask == idSecond){
                    return true;
                }else{
                    return false;
                }

            }
            
        },
        checkFilesOriginal(arg){
            let arr = arg.removeIn;

            if(arr.includes(this.$route.params.id)){

                return false;
                
            }else{

                return true;

            }
            
        }
    },
    created() {
        this.getCourse()
        this.getAttachedFiles()
        this.getAttachedFilesOriginal()

        this.socket.on('newTaskAprrenticeEdited', () => {
          this.getCourse()
        })
        this.socket.on('appreticeChangedStatus', () => {
          this.getCourse()
        })
        this.socket.on('newAttachAdded', () => {
            this.getAttachedFiles()
            this.getAttachedFilesOriginal()
        })
        this.socket.on('attachmentRemoved', () => {
            this.getAttachedFiles()
            this.getAttachedFilesOriginal()
        })

        
        
    },

}
</script>

<style lang="scss">
    .cursor-pointer{
        cursor: pointer;
    }
    .align-center-element{
        align-self: center;
    }
    .scroll {
        overflow-y: auto;
    }
</style>
